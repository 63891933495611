define('oldBrowserFormView',[
    'module',
    'template!oldBrowserFormTpl',
    'marionette',
    'settings'
  ],
function(module, formTpl, Marionette, Settings) {
  'use strict';

  var OldBrowserFormView = Marionette.ItemView.extend({
    template: formTpl,

    serializeData: function() {
      var successURL = encodeURIComponent('/#vectorization'),
        errorURL = encodeURIComponent('/#serverError'),
        postURL = '/compuzz2/rest/v2/tickets/VECTORIZATION?tokenId=' +
          Settings.get('tokenId') + '&successURL=' +
          successURL + '&errorURL=' + errorURL;
      return {
        url: postURL
      };
    }
  });

  module.exports = OldBrowserFormView;
});
